<template>
  <div class="container">
    <div class="wrap">
      <!-- CONTENT [S] -->
      <div class="content qa">
        <div class="main_banner">
          <img src="../assets/images/sub_banner_03.jpg" alt="" class="pc" />
          <img src="../assets/images/m_sub_banner_03.jpg" alt="" class="mo" />
          <div class="main_text_area">
            <p>{{$t('comn.soyes.menu.partnershipInquiries.name')}}</p>
            <span>{{$t('comn.soyes.menu.partnershipInquiries.description')}}</span>
          </div>
        </div>
        <div class="qa_area">
          <div class="common_inner">
            <div class="left_area">
              <p class="main_text">{{ $t('main.soyes.question.regist') }}</p>
              <p class="sub_text v2">
                {{ $t('main.soyes.question.regist.descriptionFirst') }}<br />{{ $t('main.soyes.question.regist.descriptionSecond') }}
              </p>
            </div>
            <div class="right_area">
              <div class="input_area">
                <span>{{$t('main.soyes.question.companyName')}}</span>
                <input
                  :isRequired="true"
                  v-model="questionInfo.companyName"
                  type="text"
                  :placeholder="$t('comn.soyes.placeholder.companyName.message')" 
                />
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="formValidate.companyName" style="color: red;"
                >
                  {{ formFeedback.companyName }}
                </b-form-invalid-feedback>
              </div>
              <div class="input_area">
                <span>{{$t('main.soyes.question.titleName')}}</span>
                <input
                  :isRequired="true"
                  v-model="questionInfo.emailOrPhoneNum"
                  type="text"
                  :placeholder="$t('comn.soyes.placeholder.emailOrPhoneNum.message')" 
                />
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="formValidate.emailOrPhoneNum" style="color: red;"
                >
                  {{ formFeedback.emailOrPhoneNum }}
                </b-form-invalid-feedback>
              </div>
              <div class="input_area">
                <span>{{$t('main.soyes.question.content')}}</span>
                <textarea
                  :isRequired="true"
                  v-model="questionInfo.content"
                  cols=""
                  rows=""
                  :placeholder="$t('comn.soyes.placeholder.content.message')" 
                ></textarea>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="formValidate.content" style="color: red;"
                >
                  {{ formFeedback.content }}
                </b-form-invalid-feedback>
              </div>

              <div class="check_area">
                <b-form-checkbox
                  id="chk_01"
                  v-model="questionInfo.checkValue"
                  :options="isChecked"
                />
                <label for="chk_01"
                  >
                  <strong>{{ $t('main.soyes.manager.agrmnt.serviceagryn.message') }}</strong>
                  </label
                >
              </div>
              <div>
                  <b-form-invalid-feedback
                    class="d-block"
                    v-if="formValidate.checkValue" style="color: red;"
                  >
                    {{ formFeedback.checkValue }}
                  </b-form-invalid-feedback>
              </div>
              <button type="button" class="submit_button" @click="onRegist()">
                {{ $t('comn.soyes.btn.submit.title') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- CONTENT [E] -->
    </div>
  </div>
</template>

<script>
import bbsInfoService from "@/service/bbsInfoService";

export default {
  name: "Sub3Page",
  components: {},
  data() {
    return {
      result: {
        resultCode: "",
        resultErrMsg: "",
      },
      questionInfo: {
        companyName: "",
        emailOrPhoneNum: "",
        content: "",
        checkValue: "",
      },
      bbsInfo: {
        bbsType: "M",
        secretYn: "N",
        bbsTitle: "",
        bbsContent: "",
      },
      isChecked: [
        {
          value: "Y",
          text: this.$t("comn.soyes.btn.apply.agree"),
        },
      ],
      formValidate: {
        companyName: null,
        emailOrPhoneNum: null,
        content: null,
        checkValue:null,
      },
      formFeedback: {
        companyName: null,
        emailOrPhoneNum: null,
        content: null,
        checkValue:null,
      },
    };
  },
  created() {},
  computed: {},
  methods: {
    showAlert(event) {
      event.preventDefault();
      alert(this.$t('comn.soyes.service.alert.preparing'));
    },

    onChangeInputValue(key, value) {
      console.log(key, value);
      this.questionInfo[key] = value;
    },
    onRegist() {
      if (this.validateForm()) return false;

      if (!confirm(this.$t('main.soyes.question.confirm.regist'))) {
        return;
      }
      this.bbsInfo.bbsTitle = this.questionInfo.companyName + " (" + this.questionInfo.emailOrPhoneNum + ")";
      this.bbsInfo.bbsContent = this.questionInfo.content;
      bbsInfoService.bbsRegist(this.bbsInfo).then((data) => {
        this.result = data.result;

        if (this.result.resultCode == "0000") {
          alert(this.$t("main.soyes.question.alert.regist.success"));
          this.questionInfo.companyName = '';
          this.questionInfo.emailOrPhoneNum = '';
          this.questionInfo.content = '';
          this.questionInfo.checkValue = false;

          this.bbsInfo.bbsTitle = '';
          this.bbsInfo.bbsContent = '';
        } else {
          alert(this.$t("main.soyes.question.alert.regist.fail"));
        }
      });
    },
    validateForm() {
      const validationMap = [
        {
          key: "companyName",
          condition: this.questionInfo.companyName === "",
          message: this.$t(`main.soyes.question.input.alert.companyName`),
        },
        {
          key: "emailOrPhoneNum",
          condition: this.questionInfo.emailOrPhoneNum === "",
          message: this.$t(`main.soyes.question.input.alert.emailOrPhoneNum`),
        },
        {
          key: "content",
          condition: this.questionInfo.content === "",
          message: this.$t(`main.soyes.question.input.alert.content`),
        },
        {
          key: "checkValue",
          condition: this.questionInfo.checkValue != true,
          message: this.$t(`main.soyes.question.input.alert.checkValue`),
        },
      ];

      validationMap.forEach((el) => {
        if (el) {
          this.formValidate[el.key] = el.condition;
          this.formFeedback[el.key] = el.message;
        }
      });

      return Object.keys(this.formValidate).some(
        (key) => this.formValidate[key] === true
      );
    },
  },
};
</script>

<style scoped></style>
