import i18n from '@/i18n'

export const sogom = {

    getPropAttrList( dbPropList, dbPropName ){
		let dbPropAttrList = [];
		(dbPropList || []).forEach( (dbProp, index ) => {
			let propName = dbProp.propName;
			let propAttrList = dbProp.propAttrList || [] ;
			if( propName === dbPropName){
				propAttrList.forEach( (propAttr, attIdx ) => {
					let dbPropAttrName = propAttr.propAttrDesc;
					if( i18n.locale === 'en'){
					 	dbPropAttrName = propAttr.propAttrDescEn;
					}else if( i18n.locale === 'ja'){
						dbPropAttrName = propAttr.propAttrDescJa;
					}else{
						dbPropAttrName = propAttr.propAttrDescKr;
					}
					dbPropAttrList[attIdx] = { "text" : dbPropAttrName, "value" : propAttr.propAttrValue };
				});
			}
		});
		return dbPropAttrList;
    },

	getTodayString(){
		var today = new Date();

		var year = today.getFullYear();
		var month = ('0' + (today.getMonth() + 1)).slice(-2);
		var day = ('0' + today.getDate()).slice(-2);

		var dateString = year + '-' + month  + '-' + day;

		return dateString;
	},

	getMask( phoneNumber ) {
		if(!phoneNumber) return phoneNumber
		phoneNumber = phoneNumber.replace(/[^0-9 + *]/g, '')

		let res = ''
		if(phoneNumber.length < 3) {
			res = phoneNumber
		}else {
			if(phoneNumber.substr(0, 2) =='02') {
		
				if(phoneNumber.length <= 5) {//02-123-5678
					res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3)
				}
				else if(phoneNumber.length > 5 && phoneNumber.length <= 9) {//02-123-5678
					res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3) + '-' + phoneNumber.substr(5)
				}
				else if(phoneNumber.length > 9) {//02-1234-5678
					res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 4) + '-' + phoneNumber.substr(6)
				}
		
			} else {
				if(phoneNumber.length < 8) {
					res = phoneNumber
				}
				else if(phoneNumber.length == 8)
				{
					res = phoneNumber.substr(0, 4) + '-' + phoneNumber.substr(4)
				}
				else if(phoneNumber.length == 9)
				{
					res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
				}
				else if(phoneNumber.length == 10)
				{
					res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
				}
				else if(phoneNumber.length > 10) { //010-1234-5678
					res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 4) + '-' + phoneNumber.substr(7)
				}
			}
		}
			  
		return res
	},
	
}