import Vue from "vue";
import VueRouter from "vue-router";

import MainPage from "@/page/MainPage";
import Sub1Page from "@/page/Sub1Page";
import Sub2Page from "@/page/Sub2Page";
import Sub3Page from "@/page/Sub3Page";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: MainPage,
    
  },
  {
    path: "/hotelService",
    name: "sub1",
    component: Sub1Page,
    
  },
  {
    path: "/otaService",
    name: "sub2",
    component: Sub2Page,
    
  },
  {
    path: "/partnership",
    name: "sub3",
    component: Sub3Page,
    
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//  	console.log(to)
//  	console.log(from)
//  	//console.log(next)
//  	//debugger
// })
//router.replace({ path: '/dashborad', redirect: '/dashborad' })

export default router;
