import Vue from 'vue'
import moment from 'moment'
import numeral from 'numeral'
import i18n from '@/i18n'


Vue.filter("formatDate", (value, format = "YYYY-MM-DD HH:mm:ss") => {
  if (!value) {
    return "";
  }
  return moment(new Date(value)).format(format);
});

Vue.filter("formatDateTimeToDate", (value, format = "YYYY-MM-DD") => {
    if (!value) {
      return "";
    }
    return moment(new Date(value)).format(format);
});

Vue.filter("timeForToday", (value) => {
    const today = new Date();
    const timeValue = new Date(value);

    const btnTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
    if (btnTime < 1){
        return i18n('comn.soyes.datetime.twtstyle.just.before.title')
    }
    if (btnTime < 60){
        return btnTime +i18n.t('comn.soyes.datetime.twtstyle.minute.before.title')
    }
    const btnHour = Math.floor( btnTime / 60)
    if (btnHour < 24){
        return btnHour +i18n.t('comn.soyes.datetime.twtstyle.hour.before.title')
    }
    const btnDay = Math.floor( btnTime / 60 / 24 )
    if (btnDay < 365){
        return btnDay +i18n.t('comn.soyes.datetime.twtstyle.day.before.title')
    }
    return Math.floor( btnDay / 365) + i18n.t('comn.soyes.datetime.twtstyle.year.before.title')
});

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0");
});

Vue.filter('phoneNumber', function (phoneNumber) {

  if(!phoneNumber) return phoneNumber

  phoneNumber = phoneNumber.replace(/[^0-9]/g, '')

  let tmp = ''
  if( phoneNumber.length < 4){
      return phoneNumber;
  }
  else if(phoneNumber.length < 7)
  {
      tmp += phoneNumber.substr(0, 3);
      tmp += '-';
      tmp += phoneNumber.substr(3);
      return tmp;
  }
  else if(phoneNumber.length == 8)
  {
      tmp += phoneNumber.substr(0, 4);
      tmp += '-';
      tmp += phoneNumber.substr(4);
      return tmp;
  }
  else if(phoneNumber.length < 10)
  {
      if(phoneNumber.substr(0, 2) =='02') { //02-123-5678            
          tmp += phoneNumber.substr(0, 2);
          tmp += '-';
          tmp += phoneNumber.substr(2, 3);
          tmp += '-';
          tmp += phoneNumber.substr(5);
          return tmp;
      }
  }
  else if(phoneNumber.length < 11)
  {
      if(phoneNumber.substr(0, 2) =='02') { //02-1234-5678            
          tmp += phoneNumber.substr(0, 2);
          tmp += '-';
          tmp += phoneNumber.substr(2, 4);
          tmp += '-';
          tmp += phoneNumber.substr(6);
          return tmp;
      } else {                        //010-123-4567            
          tmp += phoneNumber.substr(0, 3);
          tmp += '-';
          tmp += phoneNumber.substr(3, 3);
          tmp += '-';
          tmp += phoneNumber.substr(6);
          return tmp;
      }
  }
  else { //010-1234-5678        
      tmp += phoneNumber.substr(0, 3);
      tmp += '-';
      tmp += phoneNumber.substr(3, 4);
      tmp += '-';
      tmp += phoneNumber.substr(7);
      return tmp;
  }

})

// 숫자 단위 , 추가
Vue.filter("commaNumber", function (commaNumber) {
    return String(commaNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})
