<template>
  <div class="content service">
    <div class="main_banner">
      <img src="../assets/images/sub_banner_01.jpg" alt="" class="pc" />
      <img src="../assets/images/m_sub_banner_01.jpg" alt="" class="mo" />
      <div class="main_text_area">
        <p>{{$t('comn.soyes.menu.service.hotel.name')}}</p>
        <span>{{$t('comn.soyes.menu.service.hotel.subtitle')}}</span>
      </div>
    </div>
    <div class="content_one">
      <div class="common_inner">
        <p class="main_text">
          {{$t('comn.soyes.menu.service.hotel.func1.name')}}
        </p>
        <p class="sub_text v2"> 
          {{$t('comn.soyes.menu.service.hotel.func1.detail')}}
        </p>
        <div class="ota_box">
          <div class="box">
            <img src="../assets/images/icon_sub01_01.png" :alt="$t('comn.soyes.menu.service.hotel.func1.features1.name')"/>
            <span>{{$t('comn.soyes.menu.service.hotel.func1.features1.name')}}</span>
          </div>
          <div class="box">
            <img src="../assets/images/icon_sub01_02.png" :alt="$t('comn.soyes.menu.service.hotel.func1.features2.name')" />
            <span>{{$t('comn.soyes.menu.service.hotel.func1.features2.name')}}</span>
          </div>
          <div class="box">
            <img src="../assets/images/icon_sub01_03.png" :alt="$t('comn.soyes.menu.service.hotel.func1.features3.name')" />
            <span>{{$t('comn.soyes.menu.service.hotel.func1.features3.name')}}</span>
          </div>
          <div class="box">
            <img src="../assets/images/icon_sub01_04.png" :alt="$t('comn.soyes.menu.service.hotel.func1.features4.name')" />
            <span>{{$t('comn.soyes.menu.service.hotel.func1.features4.name')}}</span>
          </div>
          <div class="box">
            <img
              src="../assets/images/icon_sub01_05.png"
              :alt="$t('comn.soyes.menu.service.hotel.func1.features5.name')"
            />
            <span>{{$t('comn.soyes.menu.service.hotel.func1.features5.name')}}</span>
          </div>
          <div class="box">
            <img
              src="../assets/images/icon_sub01_06.png"
              :alt="$t('comn.soyes.menu.service.hotel.func1.features6.name')"
            />
            <span>{{$t('comn.soyes.menu.service.hotel.func1.features6.name')}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content_two">
      <div class="common_inner">
        <p class="main_text">{{$t('comn.soyes.menu.keyFeaturesGuide.name')}}</p>
        <div class="content_box">
          <div class="left_area">
            <div class="para_box">
              <p class="para_title">
                {{$t('comn.soyes.menu.service.hotel.fun2.features1.name')}}
              </p>
              <p>
                {{$t('comn.soyes.menu.service.hotel.fun2.features1.detail')}}
              </p>
            </div>
            <div class="para_box">
              <p class="para_title">
                {{$t('comn.soyes.menu.service.hotel.fun2.features2.name')}}
              </p>
              <p>
                {{$t('comn.soyes.menu.service.hotel.fun2.features2.detail')}}
              </p>
            </div>
          </div>
          <div class="right_area">
            <div class="img_area">
              <img src="../assets/images/icon_sub01_mid.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_three">
      <div class="common_inner">
        <p class="main_text">{{$t('comn.soyes.menu.service.hotel.func3.name')}}</p>
        <p class="sub_text v2">
          {{$t('comn.soyes.menu.service.hotel.func3.detail')}}
        </p>
        <div class="text_box">
          <div class="box">
            <span>{{$t('comn.soyes.menu.service.hotel.func3.features1.name')}}</span>
            <p>
              {{$t('comn.soyes.menu.service.hotel.fun3.features1.detail')}}
            </p>
          </div>
          <div class="box">
            <span>{{$t('comn.soyes.menu.service.hotel.func3.features2.name')}}</span>
            <p>
              {{$t('comn.soyes.menu.service.hotel.fun3.features2.detail')}}
            </p>
          </div>
          <div class="box">
            <span>{{$t('comn.soyes.menu.service.hotel.func3.features3.name')}}</span>
            <p>
              {{$t('comn.soyes.menu.service.hotel.fun3.features3.detail')}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sub1Page",
  components: {},
  data() {
    return {
      isBusy: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    showAlert(event) {
      event.preventDefault();
      alert(this.$t('comn.soyes.service.alert.preparing'));
    },
  },
};
</script>

<style scoped></style>
