<template>
    <div class="header">
        <div class="common_inner">
          <h1>
            <a href="javascript:" @click="onmain"> <img src="../assets/images/logo.png" :alt="$t('comn.soyes.menu.soYes.name')"/>
            </a>
          </h1>
          <div class="menu_area">
            <ul class="menu_list">
              <li>
                <!-- <a href="/html/SOYES_TA_02sub1_v2.html">Service</a> -->
                <router-link to="">{{$t('comn.soyes.menu.service.name')}}</router-link>
                <ul class="sub_menu">
                  <li>
                    <a href="javascript:" @click="onsub1">{{$t('comn.soyes.menu.service.hotel.name')}}</a>
                  </li>
                  <li>
                    <a href="javascript:" @click="onsub2">{{$t('comn.soyes.menu.service.ota.name')}}</a>
                  </li>
                  <!-- <li><router-link to="/sub1">서비스 소개</router-link></li>
                  <li><router-link to="/sub1">호텔 서비스</router-link></li>
                  <li><router-link to="/sub2">OTA 서비스</router-link></li> -->
                </ul>
              </li>
              <li>
                <a href="javascript:" @click="showAlert">{{$t('comn.soyes.menu.guide.name')}}</a>
                <!-- <ul class="sub_menu">
                <li><a href="">서비스 소개</a></li>
                <li><a href="">호텔 서비스</a></li>
                <li><a href="">OTA 서비스</a></li>
              </ul> -->
              </li>
              <li>
                <a href="javascript:" @click="showAlert">{{$t('comn.soyes.menu.support.name')}}</a>
                <!-- <ul class="sub_menu">
                <li><a href="">서비스 소개</a></li>
                <li><a href="">호텔 서비스</a></li>
                <li><a href="">OTA 서비스</a></li>
              </ul> -->
              </li>
            </ul>
            <div class="contact_button">
              <a href="javascript:" @click="onsub3">{{$t('comn.soyes.menu.partnershipInquiries.name')}}</a>
            </div>
            <div class="m_close">
              <img src="../assets/images/m_close.png" :alt="$t('comn.soyes.btn.close.title')" />
            </div>
          </div>
          <div class="m_menu" @click="menu_open">
            <img src="../assets/images/m_menu.png" :alt="$t('comn.soyes.menu.mobile.name')" />
          </div>
        </div>
      </div>

</template>

<script>
export default {
  name: "MainPage",
  data() {
    return {
      
    };
  },
  created() {
    this.fetchData();
  },
  computed: {},
  mounted() {
    
  },
  methods: {
    fetchData(){
      
    },
    showAlert(event) {
      event.preventDefault();
      alert(this.$t('comn.soyes.service.alert.preparing'));
    },
    menu_open(){
        document.querySelector(".header .menu_area").classList.add("on");
        document.documentElement.classList.add("menu");
        document.body.classList.add("menu");
    },
    onmain(){
      const isMenuOpen = document.querySelector(".header .menu_area").classList.contains("on");

      // 사이드바가 열려 있으면 닫기
      if (isMenuOpen) {
          document.querySelector(".header .menu_area").classList.remove("on");
          document.documentElement.classList.remove("menu");
          document.body.classList.remove("menu");
      }
        
        this.$router.push({name:'main'})
    },
    onsub1(){
        //location.href = '/sub1'
        document.querySelector(".header .menu_area").classList.remove("on");
        document.documentElement.classList.remove("menu");
        document.body.classList.remove("menu");
        
        this.$router.push({name:'sub1'})
    },
    onsub2(){
        //location.href = '/sub2'
        document.querySelector(".header .menu_area").classList.remove("on");
        document.documentElement.classList.remove("menu");
        document.body.classList.remove("menu");
        
        this.$router.push({name:'sub2'})
    },
    onsub3(){
        //location.href = '/sub3'
        document.querySelector(".header .menu_area").classList.remove("on");
        document.documentElement.classList.remove("menu");
        document.body.classList.remove("menu");
        
        this.$router.push({name:'sub3'})
    }
    
  },
};
</script>