<template>
  <!-- CONTENT [S] -->
  <div class="content main">
    <div class="main_banner">
      <img src="../assets/images/main_banner.jpg" class="pc" alt="" />
      <img src="../assets/images/m_main_banner.jpg" class="mo" alt="" />
      <div class="main_text_area">
        <p>
          {{$t('comn.soyes.main.name1')}}<br />
          {{$t('comn.soyes.main.name2')}}
        </p>
        <span>{{$t('comn.soyes.main.subtitle')}}</span>
        <router-link
        :to="{
          name: 'sub2',
        }"
        >{{$t('comn.soyes.btn.detail.title')}}</router-link>
      </div>
    </div>
    <div class="content_one">
      <div class="common_inner">
        <p class="sub_text">
          Real-time reservation, channel management services
        </p>
        <p class="main_text">{{$t('comn.soyes.main.func1.name')}}</p>
        <p class="sub_text v2">
          {{$t('comn.soyes.main.func1.subtitle')}}
        </p>

        <div class="box_list">
          <div class="box">
            <div class="img_area">
              <img
                src="../assets/images/main_mid_banner_01.png"
                :alt="$t('comn.soyes.main.func1.features1.name')"
              />
            </div>
            <div class="box_text">
              <p class="box_title">
                {{$t('comn.soyes.main.func1.features1.name1')}}<br />
                {{$t('comn.soyes.main.func1.features1.name2')}}
              </p>
              <p>
                {{$t('comn.soyes.main.func1.features1.detail')}}
              </p>
            </div>
          </div>
          <div class="box">
            <div class="img_area">
              <img
                src="../assets/images/main_mid_banner_02.png"
                :alt="$t('comn.soyes.main.func1.features2.name')"
              />
            </div>
            <div class="box_text">
              <p class="box_title">{{$t('comn.soyes.main.func1.features2.name')}}</p>
              <p>
                {{$t('comn.soyes.main.func1.features2.detail')}}
              </p>
            </div>
          </div>
          <div class="box">
            <div class="img_area">
              <img
                src="../assets/images/main_mid_banner_03.png"
                :alt="$t('comn.soyes.main.func1.features3.name')"
              />
            </div>
            <div class="box_text">
              <p class="box_title">{{$t('comn.soyes.main.func1.features3.name')}}</p>
              <p>
                {{$t('comn.soyes.main.func1.features3.detail')}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content_two">
      <div class="common_inner">
        <p class="sub_text">Main Feature</p>
        <p class="main_text">{{$t('comn.soyes.menu.keyFeaturesGuide.name')}}</p>
        
        <div class="text_wrap">
          <div class="info_text_area">
            <div class="text_area">
              <span class="title">{{$t('comn.soyes.main.func2.features1.name1')}}<br />{{$t('comn.soyes.main.func2.features1.name2')}}</span>
              <ul>
                <li>{{$t('comn.soyes.main.func2.features1.detail1')}}</li>
                <li>{{$t('comn.soyes.main.func2.features1.detail2')}}</li>
                <li>{{$t('comn.soyes.main.func2.features1.detail3')}}</li>
                <li>{{$t('comn.soyes.main.func2.features1.detail4')}}</li>
              </ul>
            </div>
            <div class="text_area">
              <span class="title">{{$t('comn.soyes.main.func2.features2.name1')}}<br />{{$t('comn.soyes.main.func2.features1.name2')}}</span>
              <ul>
                <li>{{$t('comn.soyes.main.func2.features2.detail1')}}</li>
                <li>{{$t('comn.soyes.main.func2.features2.detail2')}}</li>
                <li>{{$t('comn.soyes.main.func2.features2.detail3')}}</li>
                <li>{{$t('comn.soyes.main.func2.features2.detail4')}}</li>
              </ul>
            </div>
            <div class="text_area">
              <span class="title">{{$t('comn.soyes.main.func2.features3.name1')}}<br />{{$t('comn.soyes.main.func2.features1.name2')}}</span>
              <ul>
                <li>{{$t('comn.soyes.main.func2.features3.detail1')}}</li>
                <li>{{$t('comn.soyes.main.func2.features3.detail2')}}</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- main features slide -->
        
        <div class="slide_wrap" >
          <div class="button_area">
            <button type="button" class="left swiper-button-prev">
              <img src="../assets/images/icon_arrow_left.png" :alt="$t('')" class=""/>
            </button>
            <button type="button" class="right active swiper-button-next">
              <img src="../assets/images/icon_arrow_right.png" :alt="$t('')" class=""/>
            </button>
          </div>
          <div calss="slider_area">
            <swiper class="swiper" ref="mySwiper" :options="swiperOption">
                <swiper-slide>
                  <img src="../assets/images/ta_main_fs01.png" alt="" class="slide_img"/>
                </swiper-slide>
                <swiper-slide>
                  <img src="../assets/images/ta_main_fs02.png" alt="" class="slide_img"/>
                </swiper-slide>
                <swiper-slide>
                  <img src="../assets/images/ta_main_fs03.png" alt="" class="slide_img"/>
                </swiper-slide>
                <swiper-slide>
                  <img src="../assets/images/ta_main_fs04.png" alt="" class="slide_img"/>
                </swiper-slide>
                <swiper-slide>
                  <img src="../assets/images/ta_main_fs05.png" alt="" class="slide_img"/>
                </swiper-slide>
            </swiper>
          </div>
        </div> 

      </div>
    </div>

    <div class="content_three">
      <p class="sub_text">Partnership</p>
      <p class="main_text">{{$t('comn.soyes.main.func3.name')}}</p>
      <div class="partnerlist">
        <div class="parter_box">
          <img src="../assets/images/main_partnership_logo_TL.png" alt="" />
          <!-- <img src="src/assets/images/main_partnership_logo_TL.png" alt="" /> -->
          <span class="name">{{"TL-Lincoln"}}</span>
        </div>
        <div class="parter_box">
          <img src="../assets/images/main_partnership_logo_RH.png" alt="" />
          <span class="name">{{"Rooming Hub"}}</span>
        </div>
        <div class="parter_box">
          <img src="../assets/images/main_partnership_logo_Cha.png" alt="" />
          <span class="name">{{"Chalet Korea"}}</span>
        </div>
        <div class="parter_box">
          <img src="../assets/images/main_partnership_logo_Ga.png" alt="" />
          <span class="name">{{$t("Partnership.name.gagopa")}}</span>
        </div>
        <div class="parter_box">
          <img src="../assets/images/main_partnership_logo_OK.png" alt="" />
          <span class="name">{{"Oakwood premier hotel"}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import VueAwesomeSwiper,{ Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import '/src/assets/css/swiper.css'
  Vue.use(VueAwesomeSwiper);
export default {
  name: "MainPage",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isBusy: false,
      slide: 0,
      sliding: null,
      swiperOption: {
        slidesPerView: 1,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
      currentSlideIndex: 0  // 현재 슬라이드 인덱스 초기화

    };
  },
  created() {
    this.fetchData();
  },
  computed: {},
  mounted() {
    
  },
  methods: {
    fetchData(){
        let aa = document.getElementsByClassName("html.menu::after");
        console.log(aa)
    },
  },
};

</script>

<style scoped>
swiper-button-prev {
  background-image: url(../assets/images/icon_arrow_left.png)
}
</style>
