import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import axios from 'axios'
import './util/filters'
import i18n from './i18n'
import {glovalfunc, sogom} from './util/globalFunc'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'


Vue.use(BootstrapVue);
Vue.use(IconsPlugin);


// Bootstrap axios
axios.defaults.baseURL = '/'
axios.defaults.headers.post['Content-Type'] = 'application/json'
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common.Accept = 'application/json'

// Enable Vuelidate
Vue.use(Vuelidate)


// handle 401, 404 500
axios.interceptors.response.use(
  response => {
    return response
  },
  (error) => {
    //console.log(error.response.status)
    if (error.response.status == 401) {
      location.href =  axios.defaults.baseURL + '/login'
    } else if (error.response.status == 404 || error.response.status == 500) {
      //console.log(error)
      alert( i18n.t('comn.soyes.error.connection.failure.message')+ `\n(${error.message})`)
    } else {
        return Promise.reject(error)
    }
  }
)

// add loading spinner
axios.interceptors.request.use(
  config => {
    store.commit('setLoading', true)
    return config
  },
  (error) => { return Promise.reject(error) }
)
axios.interceptors.response.use(
  response => {
    store.commit('setLoading', false)
    return response
  },
  (error) => {
    store.commit('setLoading', false)
    return Promise.reject(error)
  }
)



Vue.config.productionTip = false
Vue.config.devtools = true;
Vue.prototype.$sogom = sogom;

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
