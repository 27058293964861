<template>
  <default-layout>
    <router-view/>
  </default-layout>
</template>

<script>

import DefaultLayout from "@/layout/DefaultLayout";



export default {
  name: "App",
  components: {
    DefaultLayout
  },
};
</script>

<style lang="scss">
@import "/src/assets/css/common.css";
@import "/src/assets/css/style.css";

#app {
  width: 100%;
  height: 100%;
}
</style>
