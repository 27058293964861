import axios from "axios";

export default {
  bbsRegist(bbsInfo) {
    return new Promise((resolve, reject) => {
      //console.log(bbsInfo);
      axios
        .post("/admin/questions/bbs/regist", bbsInfo)
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
