<template>
  <div class="container">
    <div class="wrap">
      <!-- CONTENT [S] -->
      <div class="content ota">
        <div class="main_banner">
          <img src="../assets/images/sub_banner_02.jpg" alt="" class="pc" />
          <img src="../assets/images/m_sub_banner_02.jpg" alt="" class="mo" />
          <div class="main_text_area">
            <p>{{$t('comn.soyes.menu.service.ota.name')}}</p>
            <span>{{ $t('comn.soyes.menu.service.ota.subtitle') }}</span>
          </div>
        </div>
        <div class="content_one">
          <div class="common_inner">
            <p class="main_text">
              {{ $t('comn.soyes.menu.service.ota.func1.name1') }}<br>{{ $t('comn.soyes.menu.service.ota.func1.name2') }}
            </p>
            <p class="sub_text v2">
              {{ $t('comn.soyes.menu.service.ota.func1.detail') }}
            </p>
            <div class="img_area">
              <img
                src="../assets/images/sub02_mid_banner.jpg"
                alt=""
                class="pc"
              />
              <img
                src="../assets/images/m_sub02_mid_banner.jpg"
                alt=""
                class="mo"
              />
            </div>
          </div>
        </div>
        <div class="content_two">
          <div class="common_inner">
            <p class="main_text">{{ $t('comn.soyes.menu.keyFeaturesGuide.name') }}</p>
            <div class="feature_box_wrap">
              <div class="feature_box">
                <img
                  src="../assets/images/icon_sub02_01.png"
                  :alt="$t('comn.soyes.menu.service.ota.func2.features1.image.name')"
                />
                <div class="text_box">
                  <span class="title"
                    >{{$t('comn.soyes.menu.service.ota.func2.features1.name')}}</span
                  >
                  <p>
                    {{$t('comn.soyes.menu.service.ota.func2.features1.detail')}}
                  </p>
                </div>
              </div>
              <div class="feature_box">
                <img
                  src="../assets/images/icon_sub02_02.png"
                  :alt="$t('comn.soyes.menu.service.ota.func2.features3.image.name')"
                />
                <div class="text_box">
                  <span class="title"
                    >{{$t('comn.soyes.menu.service.ota.func2.features2.name')}}</span
                  >
                  <p>
                    {{$t('comn.soyes.menu.service.ota.func2.features2.detail')}}
                  </p>
                </div>
              </div>
              <div class="feature_box">
                <img
                  src="../assets/images/icon_sub02_03.png"
                  :alt="$t('comn.soyes.menu.service.ota.func2.features3.image.name')"
                />
                <div class="text_box">
                  <span class="title"
                    >{{$t('comn.soyes.menu.service.ota.func2.features3.name')}}</span
                  >
                  <p>
                    {{$t('comn.soyes.menu.service.ota.func2.features3.detail')}}
                  </p>
                </div>
              </div>
              <div class="feature_box">
                <img
                  src="../assets/images/icon_sub02_04.png"
                  :alt="$t('comn.soyes.menu.service.ota.func2.features4.image.name')"
                />
                <div class="text_box">
                  <span class="title"
                    >{{$t('comn.soyes.menu.service.ota.func2.features4.name')}}</span
                  >
                  <p>
                    {{$t('comn.soyes.menu.service.ota.func2.features4.detail')}}
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content_three">
          <div class="common_inner">
            <div class="left_area">
              <p class="main_text">{{$t('comn.soyes.menu.service.ota.func3.name')}}</p>
              <p class="sub_text v2">
                {{$t('comn.soyes.menu.service.ota.func3.features1.detail')}}
              </p>
              <a href="sub3" class="link_button">{{$t('comn.soyes.menu.partnershipInquiries.name')}}</a>
            </div>
            <div class="right_area">
              <img src="../assets/images/sub02_btm_banner.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- CONTENT [E] -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Sub2Page",
  components: {},
  data() {
    return {
      isBusy: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    showAlert(event) {
      event.preventDefault();
      alert(this.$t('comn.soyes.service.alert.preparing'));
    },
  },
};
</script>

<style scoped></style>
