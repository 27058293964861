<template>
    <div class="container">
        <div class="wrap">
            <default-header/>
            <router-view/>
            <default-footer />
        </div>
    </div>
</template>

<script>
import DefaultFooter from "@/layout/DefaultFooter";
import DefaultHeader from "@/layout/DefaultHeader";

export default {
    name: 'DefaultLayout',
    components: {
        DefaultHeader,
        DefaultFooter,
    }
}

</script>